"use client";

import { getProfile } from "api/auth";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { QueryKey } from "utils/enum";
import { useAuth } from "./useAuth";

const useMyProfile = (enabled = false) => {
  const authContext = useAuth();

  const { data: myProfileData, ...rest } = useQuery({
    queryKey: QueryKey.PROFILE,
    queryFn: () => getProfile(),
    enabled: enabled && !!authContext?.refreshToken,
  });

  return {
    myProfile: myProfileData?.data,
    ...rest,
  };
};

export default useMyProfile;
