import { getProfile, updateLineAccount } from "api/auth";
import configs from "constants/config";
import useMyProfile from "hooks/useMyProfile";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QueryKey, StateUpdateSocial } from "utils/enum";
import { handleErrorMessage } from "utils/helper";

interface IProps {
  code: string;
  state: any;
}

const CallbackPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const code: any = searchParams.get("code");
  const state: any = searchParams.get("state");

  const { myProfile } = useMyProfile(true);
  useQuery({
    queryKey: ["callbackUpdateLine", myProfile],
    queryFn: () =>
      updateLineAccount({
        code: code,
        redirectUri: `${configs.LINE_REDIRECT_URI}`,
        accountId: myProfile?.accountId,
      }),
    enabled:
      !!code &&
      !!myProfile &&
      [
        StateUpdateSocial.UPDATE_LINE,
        StateUpdateSocial.UPDATE_LINE_FROM_MY_PAGE,
      ].includes(state),
    onSuccess: () => {
      // if (state === StateUpdateSocial.UPDATE_LINE) {
      //   navigate("/setting/notification");
      // } else
      if (state === StateUpdateSocial.UPDATE_LINE_FROM_MY_PAGE) {
        navigate("/mypage");
      }
    },
    onError: (error) => {
      handleErrorMessage(error);
      // if (state === StateUpdateSocial.UPDATE_LINE) {
      //   navigate("/setting/notification");
      // } else
      if (state === StateUpdateSocial.UPDATE_LINE_FROM_MY_PAGE) {
        navigate("/mypage");
      }
    },
  });

  return <div>Verifying account ...</div>;
};

// CallbackPage.getInitialProps = ({ query }: any) => {
//   const { code, state } = query;
//   return { code, state };
// };

export default CallbackPage;
