import { Spin } from "antd";
import Header from "components/Header/Header";
import SideNav from "components/SideNav";
import { useAuth } from "hooks/useAuth";
import { useDispatchPermission } from "hooks/usePermissions";
import useToggleSideNav from "hooks/useToggleSideNav";
import { Suspense } from "react";
import { Navigate, useLocation, useOutlet } from "react-router-dom";
import { SIDE_NAV_WIDTH, SIDE_NAV_WIDTH_COLLAPSED } from "utils/const";

import { getMyPermissions } from "api/staff";
import { useQuery } from "react-query";
import { ELocalStorageKey } from "utils/enum";
import { IPermission } from "utils/interface";
import styles from "./styles.module.scss";
import useViewport from "hooks/useViewport";

export default function ProtectedRoute() {
  const { collapsed } = useToggleSideNav();
  const authContext = useAuth();
  const location = useLocation();
  const dispatchPermission = useDispatchPermission();
  const { isMobile } = useViewport();

  useQuery({
    queryKey: "myPermission",
    queryFn: () => getMyPermissions(),
    enabled: !!authContext?.refreshToken,
    onSuccess({ data }) {
      const formatData = data?.permissions.reduce((a: any, b: IPermission) => {
        a[b.feature] = b.abilities;
        return a;
      }, {} as Record<string, IPermission>);
      dispatchPermission({
        type: "update",
        data: { permissions: formatData, role: data.role },
      });
    },
  });
  const outlet = useOutlet();

  if (!authContext?.refreshToken) {
    localStorage.setItem(
      ELocalStorageKey.REDIRECT_AFTER_LOGIN,
      location.pathname
    );
    return <Navigate to="/login" />;
  }

  return (
    <div className={styles.pageWrapper}>
      <SideNav />
      <div
        className={styles.mainWrapper}
        style={{
          marginLeft: collapsed
            ? -SIDE_NAV_WIDTH + SIDE_NAV_WIDTH_COLLAPSED
            : 0,
        }}
      >
        <Header />
        <div className={styles.pageContent}>
          <Suspense
            fallback={
              <div className="spin">
                <Spin />
              </div>
            }
          >
            {outlet}
          </Suspense>
        </div>
      </div>
    </div>
  );
}
