import { ILoginParams } from "utils/interface";
import { sendDelete, sendGet, sendPatch, sendPost } from "./axios";

interface ILineItem {
  id: number;
  accountId: number;
  lineAccountName: string;
  lineAccountId: string;
}
interface ILine {
  data: Array<ILineItem>;
  totalItems: number;
}
interface IUpdateSNS {
  code: string;
  redirectUri: string;
  accountId: number | string;
}

export const loginAPI = (params: ILoginParams): Promise<any> =>
  sendPost("/operation/auth/login", params);

export const getProfile = () => sendGet(`/operation/auth/profile`);

interface IParamsUpdateProfile {
  password: string;
  name: string;
  email: string;
  phone: string;
  memo:string
}

export const updateProfile = (params: IParamsUpdateProfile) =>
  sendPatch(`/operation/auth/profile`, params);

export const getListLineApi = (accountId: number): Promise<ILine> =>
  sendGet(`/operation/auth/line-accounts/${accountId}`).then((res) => res);

export const deleteLineApi = ({ accountId, id }: any) =>
  sendDelete(`/operation/auth/line-accounts/${accountId}/delete/${id}`).then(
    (res) => res
  );

export const updateLineAccount = (payload: IUpdateSNS) =>
  sendPatch(`/operation/auth/line-account-id`, payload);
